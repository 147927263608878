<template>
  <div :class="`${prefixCls}-introduction`" id="aochenIntro1">
    <Separate title="公司简介" desc="COMPANY PROFILE" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div
        :class="`${prefixCls}-introduction-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
      <div class="box">
        <div class="company-introduction-desc">
          <p>浙江仟源海力生制药系山西仟源医药集团股份有限公司下属控股子公司。仟源医药集团是一家集研发、生产、营销为一体的医药上市集团企业（300254），集团旗下拥有10几家子公司。公司前身普陀山制药厂成立于1989年7月15日，是以生产天然海洋药物、天然矿产药物和化工合成药物为主的药品生产企业，系浙江省首批创新型试点企业、高新技术企业、省医药行业重点骨干企业...
          </p>
          <div class="func">
            <div class="card-box">
              <div class="img-card" v-for="item in imgList" :key="item.id" @click="handleGo2(item)">
                <img :src="item.icon" alt="" />
                <span class="labelCn">{{ item.labelCn }}</span>
                <span class="labelEn">{{ item.labelEn }}</span>
              </div>
            </div>
          </div>
        </div>
        <img style="margin-left: 25px;" src="@/assets/image/home/company.jpg" alt="">
      </div>
      <components-more-btn @click="handleDetail" />
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { WowMixin } from '@/mixin/wow.js'
import ComponentsMoreBtn from '@/components/MoreBtn/index.vue'
export default {
  name: 'HomeComponentsIntroduction',
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      funcList: [
        {
          id: 1,
          labelCn: '企业文化',
          labelEn: 'Enterprise Culture'
        },
        {
          id: 2,
          labelCn: '社会责任',
          labelEn: 'Social Responsibility'
        }, {
          id: 3,
          labelCn: '企业荣誉',
          labelEn: 'Enterprise Honor'
        }
      ],
      imgList: [
        {
          id: 1,
          labelCn: '企业文化',
          labelEn: 'Enterprise Culture',
          href: 'aochenCulture',
          icon: require('@/assets/image/home/culture.png'),
          path: '/aochen/culture'
        },
        {
          id: 2,
          labelCn: '社会责任',
          labelEn: 'Social Responsibility',
          href: 'aochenSocial',
          icon: require('@/assets/image/home/social.png'),
          path: '/aochen/social'
        },
        {
          id: 3,
          labelCn: '资质荣誉',
          labelEn: 'Qualification Honor',
          href: 'aochenHonors',
          icon: require('@/assets/image/home/honor.png'),
          path: '/aochen/honors'
        }
      ]
    }
  },
  mixins: [WowMixin],
  components: {
    ComponentsMoreBtn,
    Separate
  },
  methods: {
    handleDetail () {
      this.$router.push({ path: '/aochen/intro' })
      setTimeout(() => {
        document.querySelector('#aochenIntro').scrollIntoView({
          behavior: 'smooth'
        })
      }, 1000)
    },
    handleGo2 ({ path, href }) {
      this.$router.push({ path })
      setTimeout(() => {
        document.querySelector(`#${href}`).scrollIntoView({
          behavior: 'smooth'
        })
      }, 1000)
    }
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -home-introduction;
  .#{$prefixCls}{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: $content-width;
    //padding-bottom: 50px;
    overflow: hidden;
    &-content{
      .box {
        display: flex;
        width: 100%;
        word-spacing: 14px;
        font-size: 14px;
        line-height: 2;
        text-align: left;
        color: #707B7C;
        img {
          height: 330px;
          object-fit: contain;
          //margin-left: 15px;
        }
        .company-introduction-desc {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          //height: 100%;
          text-indent: 2em;
          letter-spacing: 1px;
          p {
            text-align: justify;
          }
          //.desc {
          //  font-size: 18px;
          //  p {
          //    text-indent: 2em;
          //    text-align: justify;
          //  }
          //}
          .card-box {
            display: flex;
            justify-content: space-around;
            align-items: center;
            .img-card {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              cursor: pointer;
              img {
                width: 40px;
                height: 46px;
              }
              .labelCn {
                color: #666;
                font-size: 14px;
                margin: 3px 0;
              }
              .labelEn {
                color: #999;
                font-size: 14px;
                word-spacing: 0px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
</style>
