<template>
  <div :class="prefixCls" @click="clickBtn">
    <div :class="`${prefixCls}-text`">
      MORE
    </div>
    <div :class="`${prefixCls}-arrows`">
      <img src="../../assets/image/right.png" alt="more">
    </div>
  </div>
</template>

<script>
export default {
  name: 'componentsMoreBtn',
  data () {
    return {
      prefixCls: this.$prefix + '-components-more'
    }
  },
  methods: {
    clickBtn () {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -components-more;
  .#{$prefixCls}{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 100px;
    height: 40px;
    margin: 20px auto;
    &:hover &-text{
      border-color: #2C3E50;
    }
    &-text{
      padding: 0 40px;
      font-weight: 600;
      background-color: #F7F9F9;
      border: 2px solid #F7F9F9;
      color: #2C3E50;
      height: 100%;
      transition: all 0.5s ease;
      display: flex;
      align-items: center;

    }
    &-arrows{
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: #2C3E50;
      height: 100%;
      border: 2px solid #2C3E50;
      padding: 0 15px;
      img{
        width: 40px;
      }
    }
  }
</style>
