<template>
  <div class="notice-card-container">
    <div class="notice-card-container-header" />
    <div class="notice-card-container-content">
      <div class="message-box">
        <div class="message-box-header">
          <div class="title-layout">
            <img src="@/assets/image/notice-icon.png" alt="" />
            <span class="title">{{ title }}</span>
          </div>
          <!--          <span class="more-btn" @click="handleCheckMore">更多></span>-->
        </div>
        <div class="message-box-content">
          <template v-if="messageList.length !== 0">
            <div
                class="message"
                v-for="item in messageList"
                :key="item.id"
                @click="handleDetail(item)"
            >
              <div class="title-layout">
                <div class="circle" />
                <span class="title">{{ item.title }}</span>
              </div>
              <span class="date">{{ item.createDate }}</span>
            </div>
          </template>
          <!--          <no-data :image-size="70" v-else />-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPage } from '@/api'
// import NoData from '@/components/NoData/index.vue'

export default {
  name: 'NoticeCard',
  // components: { NoData },
  props: {
    title: {
      type: String,
      default: undefined
    }
  },
  data () {
    return {
      messageList: [
      ]
    }
  },
  created () {
    this.getPage()
  },
  methods: {
    handleDetail (data) {
      this.$router.push({ path: '/notice-detail', query: { id: data.id } })
    },
    handleCheckMore () {
      this.$router.push({ path: '/notice', query: { type: this.type } })
    },
    getPage () {
      getPage({ channelId: 35 }, { channelId: 0, publish: '0' }).then(
        ({ data }) => {
          this.messageList = data.data.rows
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.notice-card-container {
  //width: 594px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 15px 0px #dcdcdc;
  &-header {
    width: 100%;
    height: 6px;
    background-color: #1a70b6;
  }
  &-content {
    width: 100%;
    height: 250px;
    padding: 25px 15px;
    .message-box {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-layout {
          display: flex;
          align-items: center;
          img {
            width: 26px;
            height: 26px;
            margin-right: 8px;
          }
          .title {
            font-size: 26px;
            font-family: Source Han Sans CN-Bold, Source Han Sans CN;
            font-weight: bold;
            color: #312d2d;
            line-height: 30px;
          }
        }
        .more-btn {
          font-size: 16px;
          font-family: Source Han Sans CN-Regular, PingFang SC;
          font-weight: 400;
          color: #000000;
          line-height: 20px;
          cursor: pointer;
        }
      }
      &-content {
        width: 100%;
        flex: 1;
        overflow: hidden;
        margin-top: 20px;
        .message {
          width: 100%;
          height: 40px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          &:hover {
            border-bottom: 1px solid #cbcbcb;
          }
          .title-layout {
            display: flex;
            align-items: center;
            .circle {
              width: 4px;
              height: 4px;
              background: #1a70b6;
              margin-right: 10px;
            }
            .title {
              font-size: 14px;
              font-family: PingFang SC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 20px;
            }
          }
          .date {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #b6b6b6;
            line-height: 20px;
          }
        }
      }
    }
  }
}
</style>
