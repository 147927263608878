<template>
  <div :class="prefixCls">
    <div :class="`${prefixCls}-carousel`">
      <Carousel :carouselData="swipeList" />
    </div>
    <notice-announcement />
<!--    <Introduction />-->
<!--    <development-history />-->
<!--    <product-center />-->
    <news-center />
  </div>
</template>

<script>
import Carousel from '../../components/Carousel/index.vue'
import Introduction from './components/introduction.vue'
// import { getFrontPage } from '../../api/home'
import DevelopmentHistory from '@/pages/home/components/DevelopmentHistory.vue'
// import ProductCenter from '@/pages/home/components/ProductCenter.vue'
import NewsCenter from '@/pages/home/components/NewsCenter.vue'
import { getBannerByChannelId } from '@/api'
import NoticeAnnouncement from '@/pages/home/components/NoticeAnnouncement.vue'

export default {
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      homeData: {

      },
      showServe: false,
      swipeList: []
    }
  },
  created () {
    this.getBannerByChannelId()
    // getFrontPage().then((res) => {
    //   this.showServe = true
    //   this.homeData = res.data.data
    // })
  },
  methods: {
    getBannerByChannelId () {
      getBannerByChannelId(1).then(({ data }) => {
        this.swipeList = data.data
      })
    }
  },
  components: {
    NoticeAnnouncement,
    NewsCenter,
    // ProductCenter,
    DevelopmentHistory,
    Introduction,
    Carousel
  }
}
</script>

<style lang="scss" scoped>
  $prefixCls: $namespace + -home;
  .#{$prefixCls}{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &-carousel{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      overflow: hidden;
    }
  }
</style>
