<template>
  <div :class="`${prefixCls}-history`" id="DevelopmentHistory">
    <Separate title="发展历程" desc="DEVELOPMENT HISTORY" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div
        :class="`${prefixCls}-history-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
      <el-carousel class="history-carousel" height="250px" :autoplay="false">
        <el-carousel-item v-for="(element, index) in list" :key="index">
          <div class="history-card" v-for="item in element" :key="item.timeBlock">
            <div class="content">
              <img :src="item.pic" alt="">
              <div class="text">{{ item.content }}</div>
            </div>
            <div class="timeBlock">{{ item.title }}</div>
            <div class="time-point" />
            <div class="line" />
            <div class="desc">{{ item.content }}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { getAllList } from '@/api'
export default {
  name: 'DevelopmentHistory',
  components: { Separate },
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      historyList: []
    }
  },
  computed: {
    list () {
      const data = []
      for (let i = 0; i < this.historyList.length; i += 6) {
        data.push(this.historyList.slice(i, i + 6))
      }
      return data
    }
  },
  created () {
    this.getAllList()
  },
  methods: {
    getAllList () {
      getAllList().then(({ data }) => {
        this.historyList = data.data
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-history;
@mixin ellipsis($n) {
  overflow: hidden;
  text-overflow: ellipsis;
  @if ($n > 1) {
    display: -webkit-box;
    -webkit-line-clamp: $n;
    -webkit-box-orient: vertical;
  } @else {
    white-space: nowrap;
  }
}
.#{$prefixCls}{
  width: 100%;
  height: 705px;
  background-image: url("~@/assets/image/home/history.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 50px;
  overflow: hidden;
  &-content {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    //max-width: 100%;
    //padding: 0 15px;
    padding: 0 20px;
    flex: 1;
    //overflow: auto;
    .history-card {
      position: relative;
      display: flex;
      flex: 1;
      gap: 40px;
      z-index: 2;
      flex-shrink: 0;
      cursor: pointer;
      &:hover {
        .content {
          display: block;
        }
      }
      .timeBlock {
        font-size: 32px;
        color: #333;
        line-height: 32px;
        font-family: arial;
        font-weight: bold;
        font-style: italic;
      }
      .time-point {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 40px;
        height: 40px;
        background: url("~@/assets/image/home/timePoint.png") no-repeat center center;
        z-index: 1;
      }
      .desc {
        position: relative;
        font-size: 14px;
        color: #333;
        line-height: 24px;
        @include ellipsis(3);
        z-index: 0;
        padding: 0 20px;
      }
      .block {
        width: 336px;
        background-color: #fff;
        padding: 10px;
        display: none;
        z-index: 10;
        box-shadow: 0 7px 16px 0 rgba(7,17,27,.16);
        img {
          width: 100%;
          object-fit: contain;
          margin-bottom: 10px;
        }
        p {
          line-height: 24px;
          font-size: 16px;
          color: #666;
        }
      }
      .content {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: max-content;
        display: none;
        background-color: #fff;
        z-index: 100;
        padding: 10px;
        font-size: 14px;
        color: #333;
        line-height: 24px;
        box-shadow: 0 7px 18px 0 rgba(7, 17, 27, 0.16);
        img {
          width: 100%;
        }
      }
      &:nth-child(odd) {
        flex-direction: column;
        .timeBlock {
          margin-top: 20px;
        }
      }
      &:nth-child(even) {
        flex-direction: column-reverse;
        .timeBlock {
          margin-bottom: 35px;
        }
      }
      .line {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 100%;
        height: 1px;
        background-color: rgb(104, 104, 104);
        z-index: 2;
      }
    }
    .timeLine {
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 1;
    }
  }
}
</style>
