<template>
  <div :class="`${prefixCls}-product`" id="ProductCenter">
    <Separate type="light" title="新闻中心" desc="NEWS CENTER" class="wow animate__fadeInUp" data-wow-duration="1s"></Separate>
    <div
        :class="`${prefixCls}-product-content wow animate__fadeInUp`"
        data-wow-duration="2s"
        data-wow-delay="0.5s"
    >
      <div class="product-type-nav">
        <div class="product-type" v-for="item in typeNavList" :key="item.id" :class="{ active: active === item.id }"
             @click="handleNavClick(item.id)">
          <div class="icon-layout">
            <em class="iconfont icon" :class="item.icon" />
          </div>
          <div class="label">{{item.label}}</div>
        </div>
      </div>
      <div class="product-content">
        <el-carousel ref="carousel" class="news-carousel" :autoplay="false">
          <el-carousel-item v-for="(item, index) in list" :key="index">
            <div class="news-card" v-for="element in item" :key="element.id" @click="handleClick(element)">
              <div class="img-box">
                <img :src="element.pic" alt="">
              </div>
              <div class="title">{{ element.name }}</div>
              <div class="label">{{ element.simpleContent }}
              </div>
              <div class="func-time-layout">
                <span class="time">{{ element.publishTime | formatDate }}</span>
                <span class="detail">查看详情</span>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import Separate from '../../../components/Separate/index'
import { siteNewsPage } from '@/api/index'
import { validatenull } from '@/utils/validate'
import moment from 'moment'
export default {
  name: 'NewsCenter',
  components: { Separate },
  filters: {
    formatDate (val) {
      if (validatenull(val)) return '--'
      return moment(val).format('yyyy-MM-DD')
    }
  },
  data () {
    return {
      prefixCls: this.$prefix + '-home',
      active: '25',
      newsList: [],
      typeNavList: [
        {
          id: '25',
          label: '企业动态',
          icon: 'icon-dongtai',
          children: []
        },
        {
          id: '26',
          label: '行业资讯',
          icon: 'icon-zixun',
          children: []
        },
        {
          id: '27',
          label: '招采信息',
          icon: 'icon-zhaopin',
          children: []
        }
      ]
    }
  },
  computed: {
    list () {
      const data = []
      for (let i = 0; i < this.newsList.length; i += 3) {
        data.push(this.newsList.slice(i, i + 3))
      }
      return data
    }
  },
  created () {
    this.siteNewsPage()
  },
  methods: {
    siteNewsPage () {
      siteNewsPage({ channelId: this.active }, { channelId: 0 }).then(
        ({ data }) => {
          this.newsList = data.data.rows
        }
      )
    },
    handleNavClick (id) {
      this.active = id
      this.siteNewsPage()
    },
    handleChange (index) {
      this.active = index
    },
    handleClick (data) {
      this.$router.push({ path: 'detail', query: { id: data.id } })
    }
  }
}
</script>

<style lang="scss" scoped>
$prefixCls: $namespace + -home-product;
.#{$prefixCls}{
  width: 100%;
  height: 705px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 50px;
  overflow: hidden;
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    flex: 1;
    overflow: hidden;
    .product-type-nav {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;
      cursor: pointer;
      .product-type {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .icon-layout {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          margin-bottom: 10px;
          .icon {
            font-size: 24px;
            color: rgb(0, 49, 132);
          }
        }
        &.active {
          .icon-layout {
            border: 1px solid rgb(0, 49, 132);
          }
        }
        &:hover {
          .icon-layout {
            background-color: #fff;
            color: rgb(0, 49, 132);
          }
        }
        .label {
          width: 5em;
          font-size: 14px;
        }
      }
    }
    .product-content {
      width: $content-width;
      flex: 1;
      overflow: hidden;
      background-color: #fff;
      margin-top: 20px;
    }
  }
}
</style>
