<script>
import Separate from '@/components/Separate/index.vue'
import NoticeCard from '@/pages/home/components/NoticeCard.vue'

export default {
  name: 'NoticeAnnouncement',
  components: { NoticeCard, Separate }
}
</script>

<template>
  <div class="notice-announcement-container" id="NoticeAnnouncement">
    <Separate title="通知公告" desc="NOTICE ANNOUNCEMENT" class="wow animate__fadeInUp" data-wow-duration="1s" />
    <div class="notice-announcement-container-content">
      <div class="notice-box">
        <notice-card title="通知公告" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.notice-announcement-container {
  width: 100%;
  &-content {
    display: flex;
    justify-content: center;
    width: 100%;
    .notice-box {
      width: $content-width;
    }
  }
}
</style>
